var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hovering__pad p-4 mx-auto",staticStyle:{"max-width":"375px"}},[_c('h1',{staticClass:"mb-5"},[_vm._v("Заявка на вступление в рекламное агенство")]),(_vm.sch && _vm.model)?_c('meta-data-form',{ref:"form",attrs:{"formGroupStyle":'margin-bottom: 40px',"grid":[
      ['coverage_min', 'coverage_max', 'coverage_screen'],
      ['stories_price_min', 'stories_price_max'],
      ['call_date', 'call_time'] ],"excludeFields":['blogger'],"agreeMark":true,"overrides":{
      pr_count: {
        required: true,
      },
      manager_partnership: {
        required: true,
      },
      cancelled_partnership_reasons: {
        required: true,
      },
      call_date: {
        required_star: false,
        attrs: { cols: 7 },
      },
      call_time: {
        required_star: false,
      },
      coverage_screen: {
        nolabel: true,
        required: true,
        upload_path: '/api/pr_agency/upload-request-screenshot/',
      },
      coverage_min: {
        required: true,
      },
      coverage_max: {
        required: true,
      },
      stories_price_min: {
        required: true,
      },
      stories_price_max: {
        required: true,
      },
      instaname: { required: true },
    },"conditions":{
      pr_count: {
        handler: function handler() {
          return !!_vm.model.service;
        },
      },
      manager_partnership: {
        handler: function handler() {
          if (_vm.model.service) {
            return _vm.services.filter(function (val) { return val.id === _vm.model.service; })[0].funccode === 'partner';
          }
        },
      },
      cancelled_partnership_reasons: {
        handler: function handler() {
          if (_vm.model.service) {
            return _vm.services.filter(function (val) { return val.id === _vm.model.service; })[0].funccode === 'partner';
          }
        },
      },
      coverage_min: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
      coverage_max: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
      coverage_screen: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
      stories_price_min: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
      stories_price_max: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
      instaname: {
        handler: function handler() {
          return !_vm.currentUser.blogger_profile;
        },
      },
    },"metadata":_vm.sch},scopedSlots:_vm._u([{key:"outRow(call_date)",fn:function(){return [_c('label',{staticClass:"fs-16 mb-3"},[_vm._v("Чтобы обсудить условия, укажите удобную дату и время для звонка "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])]},proxy:true},{key:"outRow(coverage_min)",fn:function(){return [_c('label',{staticClass:"fs-16 required__star"},[_vm._v("Ваши средние охваты сторис")]),_c('p',{staticClass:"mb-3"},[_vm._v(" Заполните поля и загрузите подтверждающий скриншот с охватами сторис ")])]},proxy:true},{key:"outRow(stories_price_min)",fn:function(){return [_c('label',{staticClass:"fs-16 required__star"},[_vm._v("Цена за рекламу в сторис в ₽")])]},proxy:true},{key:"service",fn:function(ref){
    var validations = ref.validations;
return [_c('b-form-group',{attrs:{"label":"Выберите услуги:"}},_vm._l((_vm.services),function(service){return _c('div',{key:service.id,staticClass:"p-3 mb-2",class:{ error: validations.model.service.$anyError },staticStyle:{"border-radius":"5px","border":"1px solid #d5dfe4","background-color":"white"}},[_c('b-form-checkbox',{staticStyle:{"font-size":"14px !important"},attrs:{"size":"lg","value":service.id,"checked":_vm.model.service,"unchecked-value":null},on:{"change":function () {
                _vm.model.service = service.id;
              }}},[_vm._v(_vm._s(service.name))]),_c('b-collapse',{attrs:{"visible":_vm.model.service === service.id}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.model.service === service.id)?_c('div',[_c('p',{staticClass:"fw-500 mt-2"},[_vm._v("В обязанности входит:")]),_c('ul',_vm._l((service.responsibilities),function(resp){return _c('li',{key:resp.id,staticStyle:{"list-style-type":"disc","margin-left":"25px"}},[_vm._v(" "+_vm._s(resp.text)+" ")])}),0),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(service.condition))])]):_vm._e()])],1)],1)}),0)]}},{key:"call_date_title",fn:function(){return [_c('label',{staticClass:"fs-16"},[_vm._v("Дата")])]},proxy:true},{key:"call_time_title",fn:function(){return [_c('label',{staticClass:"fs-16"},[_vm._v("Время")])]},proxy:true},{key:"coverage_min_title",fn:function(){return [_c('label',{staticClass:"fw-500 fs-12 mb-1"},[_vm._v("Минимум")])]},proxy:true},{key:"coverage_max_title",fn:function(){return [_c('label',{staticClass:"fw-500 fs-12 mb-1"},[_vm._v("Максимум")])]},proxy:true},{key:"stories_price_min_title",fn:function(){return [_c('label',{staticClass:"fw-500 fs-12 mb-1"},[_vm._v("От")])]},proxy:true},{key:"stories_price_max_title",fn:function(){return [_c('label',{staticClass:"fw-500 fs-12 mb-1"},[_vm._v("До")])]},proxy:true}],null,false,2525278510),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between mt-4"},[_c('b-button',{staticClass:"px-5",staticStyle:{"height":"40px"},attrs:{"variant":"outline-default"}},[_vm._v("Закрыть")]),_c('b-button',{staticClass:"px-5",staticStyle:{"height":"40px"},attrs:{"variant":"yellow"},on:{"click":_vm.submit}},[_vm._v("Отправить")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }