<template>
  <div class="hovering__pad p-4 mx-auto" style="max-width: 375px">
    <h1 class="mb-5">Заявка на вступление в рекламное агенство</h1>
    <meta-data-form
      ref="form"
      :formGroupStyle="'margin-bottom: 40px'"
      :grid="[
        ['coverage_min', 'coverage_max', 'coverage_screen'],
        ['stories_price_min', 'stories_price_max'],
        ['call_date', 'call_time'],
      ]"
      :excludeFields="['blogger']"
      :agreeMark="true"
      v-if="sch && model"
      v-model="model"
      :overrides="{
        pr_count: {
          required: true,
        },
        manager_partnership: {
          required: true,
        },
        cancelled_partnership_reasons: {
          required: true,
        },
        call_date: {
          required_star: false,
          attrs: { cols: 7 },
        },
        call_time: {
          required_star: false,
        },
        coverage_screen: {
          nolabel: true,
          required: true,
          upload_path: '/api/pr_agency/upload-request-screenshot/',
        },
        coverage_min: {
          required: true,
        },
        coverage_max: {
          required: true,
        },
        stories_price_min: {
          required: true,
        },
        stories_price_max: {
          required: true,
        },
        instaname: { required: true },
      }"
      :conditions="{
        pr_count: {
          handler() {
            return !!model.service;
          },
        },
        manager_partnership: {
          handler() {
            if (model.service) {
              return services.filter((val) => val.id === model.service)[0].funccode === 'partner';
            }
          },
        },
        cancelled_partnership_reasons: {
          handler() {
            if (model.service) {
              return services.filter((val) => val.id === model.service)[0].funccode === 'partner';
            }
          },
        },
        coverage_min: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
        coverage_max: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
        coverage_screen: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
        stories_price_min: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
        stories_price_max: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
        instaname: {
          handler() {
            return !currentUser.blogger_profile;
          },
        },
      }"
      :metadata="sch"
      ><template #outRow(call_date)
        ><label class="fs-16 mb-3"
          >Чтобы обсудить условия, укажите удобную дату и время для звонка
          <span style="color: red">*</span></label
        ></template
      ><template #outRow(coverage_min)
        ><label class="fs-16 required__star">Ваши средние охваты сторис</label>
        <p class="mb-3">
          Заполните поля и загрузите подтверждающий скриншот с охватами сторис
        </p></template
      ><template #outRow(stories_price_min)
        ><label class="fs-16 required__star">Цена за рекламу в сторис в ₽</label> </template
      ><template #service="{ validations }"
        ><b-form-group label="Выберите услуги:">
          <div
            :class="{ error: validations.model.service.$anyError }"
            style="border-radius: 5px; border: 1px solid #d5dfe4; background-color: white"
            class="p-3 mb-2"
            v-for="service in services"
            :key="service.id"
          >
            <b-form-checkbox
              size="lg"
              style="font-size: 14px !important"
              :value="service.id"
              :checked="model.service"
              :unchecked-value="null"
              @change="
                () => {
                  model.service = service.id;
                }
              "
              >{{ service.name }}</b-form-checkbox
            >
            <b-collapse :visible="model.service === service.id">
              <transition name="fade">
                <div v-if="model.service === service.id">
                  <p class="fw-500 mt-2">В обязанности входит:</p>
                  <ul>
                    <li
                      v-for="resp in service.responsibilities"
                      :key="resp.id"
                      style="list-style-type: disc; margin-left: 25px"
                    >
                      {{ resp.text }}
                    </li>
                  </ul>
                  <p class="mt-2">{{ service.condition }}</p>
                </div>
              </transition>
            </b-collapse>
          </div>
        </b-form-group></template
      ><template #call_date_title><label class="fs-16">Дата</label></template
      ><template #call_time_title><label class="fs-16">Время</label></template
      ><template #coverage_min_title><label class="fw-500 fs-12 mb-1">Минимум</label></template
      ><template #coverage_max_title><label class="fw-500 fs-12 mb-1">Максимум</label></template
      ><template #stories_price_min_title><label class="fw-500 fs-12 mb-1">От</label></template
      ><template #stories_price_max_title
        ><label class="fw-500 fs-12 mb-1">До</label></template
      ></meta-data-form
    >
    <div class="d-flex justify-content-between mt-4">
      <b-button style="height: 40px" class="px-5" variant="outline-default">Закрыть</b-button
      ><b-button @click="submit" style="height: 40px" class="px-5" variant="yellow"
        >Отправить</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import parsers from '@main/func/parsers';
import producing from '@main/api/producing';
import prAgency from '@main/api/prAgency';
import MetaDataForm from '../reusable/forms/MetaDataForm.vue';

export default {
  components: { MetaDataForm },
  data: () => ({
    model: null,
    sch: null,
  }),
  computed: {
    ...mapState('pr_agency', ['services']),
    ...mapState(['currentUser']),
  },
  mounted() {
    prAgency.prAgencyRequest.apiSchema().then((res) => {
      this.sch = res.actions.POST;
      this.model = parsers.djangoRestFrameworkMetadataParser(this.sch);
    });
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    submit() {
      this.$refs.form.$v.$touch();
      console.log(this.$refs.form.$v);
      if (this.$refs.form.$v.$anyError) {
        return;
      }
      prAgency.prAgencyRequest.create(this.model).then((res) => {
        if (res.id) {
          this.showMessage({
            title: 'Заявка на вступление в рекламное агентство отправлена на модерацию.',
            message: 'Ответ придет на почту в течение 3-х рабочих дней',
            icon: 1,
          });
          this.$router.push({
            name: this.currentUser.blogger_profile ? 'my_statistic' : 'reviews',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}
::v-deep .fmt-rd {
  height: 40px !important;
}
::v-deep input {
  height: 40px !important;
}
::v-deep .b-form-btn-label-control {
  height: 37px !important;
}
::v-deep .b-form-btn-label-control.form-control > .form-control {
  line-height: 27px !important;
}
</style>
